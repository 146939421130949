function formatDateTime(apiDateTime) {
  const date = new Date(apiDateTime);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${hours}:${minutes} ${day}-${month}-${year}`;
}

export default formatDateTime;
